




















































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { State } from "vuex-class";
import { timeFormat } from "@/utils/util";
import ListApplicableCities from "./components/listApplicableCities.vue";
import ApplicableCityCinemaList from "./components/applicableCityCinemaList.vue";
import BlacklistCategory from "./components/blacklistCategory.vue";
import ApplicableToBackEndCategories from "./components/applicableToBackEndCategories.vue";
import CommodityDetail from "./components/commodityDetail.vue";
import {
  apiProductFileListOfCinemaCities,
  apiAddProductProfile,
  apiGetProductProfileNumber, apiGetProfileInfo, apiEditProductProfile, apiGetAuditLog,
} from '@/api/productFile'

import type { formType } from "./productFileAdd";
import { getGoodsGoodsCategoryLists, getMarketGoodsGoodsCategoryLists } from '@/api/decorate'

@Component({
  components: {
    ListApplicableCities,
    ApplicableCityCinemaList,
    BlacklistCategory,
    ApplicableToBackEndCategories,
    CommodityDetail,
  },
})
export default class productFileDetail extends Vue {
  @State("user") user: any;
  get userInfo() {
    return this.user.userInfo;
  }
  type: any = this.$route.query.type
  categoryList = [];
  form: any = {
    applicant: "", //申请人
    applicationTime: "", //申请时间
    carrierName: "", //运营商名称
    productFileName: "", //产品档案名称
    fileNumber: "", //档案编号
    fileStatus: 1, //档案状态1-启动 0禁用
    productType: 4, //产品类型1-电影次票券 2-指定品类储值券 3-N选1兑换券 4-指定商品储值券 5-全场通用储值券
    applicableType: null, //适用类型1-全国 2-指定城市 3-指定影院
    applicableTicket: null, //适用票品1-2D票 2-2D/3D票 3-全票品
    integralValue: null, //积分价值1-开启 0-关闭
    deductionLimit: "", //抵扣上限
    remark: "", //备注
    listApplicableCities: [], //适用城市列表
    applicableCityCinemaList: [
      // {
      //   city: "",
      //   cinema: "",
      //   methodExchange: [],
      // },
    ], //适用城市影院列表
    categoryApplicableScope: 1, //品类适用范围1-主品类 2-后端类目
    associateMainCategory: [], //关联主品类
    whetherFilmSupportsOfflineVerification: 0, //电影是否支持线下核销1-支持 0-不支持
    whetherCakeSupportsOfflineVerification: 0, //蛋糕是否支持线下核销1-支持 0-不支持
    frontEndProductName: "", //前端产品名称
    rulesUse: "", //使用规则
    scopeApplicableCategories: 1, //适用类目范围1-适用全部类目 2-适用指定类目
    applicableToBackEndCategories: [], //适用后端类目
    blacklistCategory: [], //黑名单类目
    stallPrice: "", //档位价（元）
    whetherLinkShowsPrice: 1, //链接是否展示价格1-是 0-否
    commodityDetail: [], //商品明细
    invalidData: [], //无效数据
    bannerPicture: [], //banner图片
    linkAddressClose: "", //链接地址（封闭）
    linkAddressOpen: "", //链接地址（开放）
    unitProfitRate: {
      sellingPriceMinimumUnitPrice: "", //销售价最小单价
      sellingPriceMaximumUnitPrice: "", //销售价最大单价
      costPriceMinimumUnitPrice: "", //成本价最小单价
      costPriceMaximumUnitPrice: "", //成本价最大单价
      minimumProfitRate: "", //最小利润率
      maximumProfitRate: "", //最大利润率
    }, //单价利润率
  };
  checkGoodsList: any = []; //选择的商品列表
  provinceList: any = []; //省份列表
  province = ""; //请选择省份
  cityList: any = []; //城市列表
  applicableCity = []; //适用城市
  cityAllList: any = []; //所有城市列表
  rules = {
    productFileName: [
      {
        required: true,
        message: "请输入产品档案名称",
        trigger: "blur",
      },
    ],
    fileStatus: [
      {
        required: true,
        message: "请选择档案状态",
        trigger: "change",
      },
    ],
    productType: [
      {
        required: true,
        message: "请选择产品类型",
        trigger: "change",
      },
    ],
    applicableType: [
      {
        required: true,
        message: "请选择适用类型",
        trigger: "change",
      },
    ],
    applicableTicket: [
      {
        required: true,
        message: "请选择适用票品",
        trigger: "change",
      },
    ],
    integralValue: [
      {
        required: true,
        message: "请选择积分价值",
        trigger: "change",
      },
    ],
    deductionLimit: [
      {
        required: true,
        message: "请输入抵扣上限",
        trigger: "blur",
      },
    ],
    listApplicableCities: [
      {
        required: true,
        message: "请选择适用城市",
        trigger: "change",
      },
    ],
    applicableCityCinemaList: [
      {
        required: true,
        message: "请选择适用城市影院列表",
        trigger: "change",
      },
    ],
    categoryApplicableScope: [
      {
        required: true,
        message: "请选择品类适用范围",
        trigger: "change",
      },
    ],
    associateMainCategory: [
      {
        required: true,
        message: "请选择关联主品类",
        trigger: "change",
      },
    ],
    whetherFilmSupportsOfflineVerification: [
      {
        required: true,
        message: "请选择电影是否支持线下核销",
        trigger: "change",
      },
    ],
    whetherCakeSupportsOfflineVerification: [
      {
        required: true,
        message: "请选择蛋糕是否支持线下核销",
        trigger: "change",
      },
    ],
    scopeApplicableCategories: [
      {
        required: true,
        message: "请选择适用类目范围",
        trigger: "change",
      },
    ],
    applicableToBackEndCategories: [
      {
        required: true,
        message: "请选择适用后端类目",
        trigger: "change",
      },
    ],
    blacklistCategory: [
      {
        required: true,
        message: "请选择黑名单类目",
        trigger: "change",
      },
    ],
    stallPrice: [
      {
        required: true,
        message: "请输入档位价",
        trigger: "blur",
      },
    ],
    whetherLinkShowsPrice: [
      {
        required: true,
        message: "请选择链接是否展示价格",
        trigger: "change",
      },
    ],
    frontEndProductName: [
      {
        required: true,
        message: "请输入前端产品名称",
        trigger: "blur",
      },
    ],
    commodityDetail: [
      {
        required: false,
        message: "请选择商品明细",
        trigger: "change",
      },
    ],
    linkAddressClose: [
      {
        required: true,
        message: "请生成链接地址（封闭）",
        trigger: "blur",
      },
    ],
    linkAddressOpen: [
      {
        required: true,
        message: "请生成链接地址（开放）",
        trigger: "blur",
      },
    ],
  };
  log_list = [];//日志列表
  //兑换方式枚举
  privateTypeEnums = [
    // {
    //   label: "2D电子票",
    //   value: 1,
    // },
    // {
    //   label: "3D电子票",
    //   value: 2,
    // },
    // {
    //   label: "2D订座票",
    //   value: 3,
    // },
    // {
    //   label: "3D订座票",
    //   value: 4,
    // },
    // {
    //   label: "2D/3D电子票",
    //   value: 5,
    // },
    {
      label: "全票品电子票",
      value: 6,
    },
    // {
    //   label: "全票品订座票",
    //   value: 7,
    // },
  ];
  updateList(item: any){
    this.checkGoodsList = item
  }
  updateData(item:any){
    this.form[item.type] = item.data
  }
  checkDecimal(){
    if (this.form.stallPrice !== '' && !/^\d+(\.\d{0,2})?$/.test(this.form.stallPrice)) {
      this.$message.error(
          '档位价只能输入数字且最多输入两位小数'
      )
      this.form.stallPrice = ''
    }
  }
  async handleItemChange(val:any) {
    const res = await apiProductFileListOfCinemaCities({
      level: 2,
      cityId: val[0],
      page_type: 2,
    })
    this.cityList = res.lists;
    this.provinceList.map((item: any,index:any) => {
      if(item.value==val[0]){
        this.provinceList[index].cities = res.lists;
        return true;
      }
    })
  }

  async cityhandleItemChange(item:any){
    this.province = item;
    this.form.listApplicableCities = [];
    item.forEach((val:any)=>{
      let id = val[1];
      const res = this.form.listApplicableCities.find(
          (nowCity: any) => +nowCity.value === +id
      )
      if (!res) {
        const province = this.provinceList.filter((items:any) => items.value === val[0]);
        console.log(province,'provinceprovinceprovince')
        const city = province[0].cities.find(
            (city: any) => +city.value === +id
        )
        city.province_id = val[0];
        city.province = province[0].label;
        this.form.listApplicableCities.push(city)
        console.log(this.form.listApplicableCities,'this.form.listApplicableCities')
      }
    })

  }
  /** S Methods **/
  // 点击表单提交
  onSubmit(formName: string) {
    // 验证表单格式是否正确
    if( (this.form.productType == 3 || this.form.productType == 4) && this.checkGoodsList.length < 1){
      this.$message.error(
          "请先添加商品"
      );
      return false;
    }
    const refs = this.$refs[formName] as HTMLFormElement;
    refs.validate( async (valid: boolean) =>  {
      if (!valid) return;
      const post_data = Object.assign({}, this.form);
      if (post_data.applicableCityCinemaList.length > 0) {
        post_data.applicableCityCinemaList =
            post_data.applicableCityCinemaList.map((item:any) => {
              const newItem = { ...item }; // 创建对象的副本
              //@ts-ignore
              if (newItem?.cinemaList) {
                //@ts-ignore
                delete newItem.cinemaList; // 删除cinemaList属性
              }
              return newItem;
            });
      }
       await apiEditProductProfile(post_data)
      this.$router.go(-1);
    });
  }
  getGoodsCategoryLists() {
    getGoodsGoodsCategoryLists({}).then((res: any) => {
      // res.forEach((item: any) => {
      //   item.sons &&
      //   item.sons.forEach((sitem: any) => {
      //     delete sitem.sons
      //   })
      // })
      this.categoryList = res
      console.log(this.categoryList,'this.categoryListthis.categoryList')
    })
  }
  async getLogLists() {
    const res = await apiGetAuditLog({profile_id:this.$route.query.id})
    this.log_list = res;
  }
  // 获取产品档案编号
  getProductProfileNumber(): void {
    apiGetProductProfileNumber({})
        .then((data) => {
          console.log(data);
          this.form.fileNumber = data.code;
          // return this.getPermission();
        })
        .then(() => {})
        .catch(() => {});
  }
  // 判断显示隐藏
  get isShow() {
    return (key: string) => {
      const form = this.form;
      const showEnum: any = {
        applicableType: form.productType === 1,
        integralValue:
            form.productType === 1 ||
            (form.productType === 2 &&
                (form.categoryApplicableScope === 1 ||
                    form.scopeApplicableCategories === 2)),
        applicableTicket:
            form.productType === 1 &&
            (form.applicableType === 1 || form.applicableType === 2),
        provinceApplicableCity:
            form.productType === 1 && form.applicableType === 2,
        deductionLimit:
            form.productType === 1 &&
            (form.applicableType === 1 ||
                form.applicableType === 2 ||
                form.applicableType === 3),
        applicableCityCinemaList:
            form.productType === 1 && form.applicableType === 3,
        categoryApplicableScope: form.productType === 2,
        associateMainCategory:
            form.productType === 2 && form.categoryApplicableScope === 1,
        frontEndProductName:
            (form.productType === 2 &&
                (form.categoryApplicableScope === 1 ||
                    form.categoryApplicableScope === 2)) ||
            [3, 4, 5].includes(form.productType),
        rulesUse:
            (form.productType === 2 &&
                (form.categoryApplicableScope === 1 ||
                    form.categoryApplicableScope === 2)) ||
            form.productType === 4,
        scopeApplicableCategories:
            form.productType === 2 && form.categoryApplicableScope === 2,
        blacklistCategory:
            form.productType === 2 &&
            form.categoryApplicableScope === 2 &&
            (form.scopeApplicableCategories === 1 ||
                (form.scopeApplicableCategories === 2 && form.integralValue === 1)),
        applicableToBackEndCategories:
            form.productType === 2 &&
            form.categoryApplicableScope === 2 &&
            form.scopeApplicableCategories === 2,
        stallPrice: form.productType === 3,
        whetherLinkShowsPrice: form.productType === 3,
        commodityDetail: [3, 4].includes(form.productType),
        whetherFilmSupportsOfflineVerification: form.productType === 5,
        whetherCakeSupportsOfflineVerification: form.productType === 5,
      };

      return showEnum[key];
    };
  }
  changeIntegralValue() {
    this.form.rulesUse = "";
    if (
        this.form.integralValue === 1 &&
        this.form.categoryApplicableScope === 1
    )
      this.form.rulesUse = "可购买全场任意类商品";
    if (
        this.form.integralValue === 0 &&
        this.form.categoryApplicableScope === 1
    )
      this.form.rulesUse = "可购买XXX类商品 ，不可购买其他品类商品";
  }

  //省份选择 清空城市
  async changeProvince(val: any) {
    this.applicableCity = [];
    this.cityList = [];
    const res = await apiProductFileListOfCinemaCities({
      level: 2,
      cityId: val,
      page_type: 2,
    });
    this.cityList = res.lists;
  }
  //适用城市列表选中
  changeApplicableCity(val: any) {
    val.map((id: any) => {
      const res = this.form.listApplicableCities.find(
          (nowCity: any) => +nowCity.value === +id
      );
      if (!res) {
        const city = this.cityList.find((city: any) => +city.value === +id);
        this.form.listApplicableCities.push(city);
      }
    });
  }
  //获取省份
  async getProvince() {
    const res = await apiProductFileListOfCinemaCities({
      level: 1,
      page_type: 2,
      list_type: 2,
    });

    this.provinceList = res;

    const res2 = await apiProductFileListOfCinemaCities({
      level: 2,
      page_type: 2,
    })

    this.cityAllList = res2.lists
    // const res2 = await apiProductFileListOfCinemaCities({
    //   level: 2,
    //   page_type: 2,
    // });
    //
    // this.cityAllList = res2.lists;
  }
  //获取省份
  async getProfileInfo() {

    const res = await apiGetProfileInfo({profile_id:this.$route.query.id});
    this.form = res;
    if(this.form.listApplicableCities.length > 0){
      let province: any = [];
      this.form.listApplicableCities.forEach((val:any)=>{
        console.log(val,'valvalval')
        if(val.province_id !=null && val.value !=null){
          province.push([val.province_id,val.cityId])
        }
      })
      this.province = province;
    }
  }

  updateProvince(){
    let province: any = [];
    console.log(this.form.listApplicableCities,'this.form.listApplicableCities')
    this.form.listApplicableCities.forEach((val:any)=>{
      if(val.province_id != null && (val.value !=null)){
        province.push([val.province_id,val.value])
      }
      console.log([val.province_id,val.value],'[val.province_id,val.cityId]')
    })
    this.province = province;
  }

  created() {
    this.getProfileInfo();
    this.getProvince();
    this.getLogLists();

    this.getGoodsCategoryLists();
    this.form.applicationTime = timeFormat(
        new Date().getTime(),
        "yyyy-mm-dd hh:MM:ss"
    );
  }
}
